import axios from 'axios';
import '../App';
import { useState } from 'react';

export default function ProductPage() {
	const [selectedProduct, setSelectedProduct] = useState('first-steps');
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');

	const handlePayment = async (event) => {
		event.preventDefault();
		const paymentData = {
			email: email,
			name: name,
			product: selectedProduct,
			selectedProduct: selectedProduct,
			region: 'ru',
		};
		const request = await axios.post('https://products.simplesmm.org/api/payments/create', paymentData);
		const { ok } = request.data;
		if (ok) {
			window.location.replace(request.data.url);
		} else {
			alert('Something went wrong');
		}
	};

	// CREATE TABLE pdf_orders(order_id TEXT, email TEXT, product TEXT, created_at DATE);
	return (
		<div className='container'>
			<div className='header'>
				<div className='header-bg'>
					<div className='header-content'>
						<span>Доступными словами про ведение соцсетей</span>
						<h1>Simple SMM</h1>
						<br />
						<span>Оформление заказа</span>
						<br />
					</div>
				</div>
			</div>
			<form className='content' onSubmit={handlePayment}>
				<span className='labelB'>Выберете тариф:</span>
				<select value={selectedProduct} onChange={(event) => setSelectedProduct(event.target.value)} className='input'>
					<option value='first-steps'>Первые шаги</option>
					<option value='advanced'>Продвинутые</option>
					<option value='consultation'>Консультация</option>
				</select>
				<br />
				{selectedProduct === 'first-steps' && (
					<div className='text'>
						Стоимость: 190 рублей.
						<br />
						<h3>Кому подойдёт?</h3>
						Всем кто только начинает свой путь в SMM.
						<br />
						<br />
						Три базовых чек-листа:
						<br />
						• Как работать с блогерами;
						<br />
						• Съёмки; <br />
						• Как составить контент-план.
						<br />
					</div>
				)}
				{selectedProduct === 'advanced' && (
					<div className='text'>
						Стоимость: 890 рублей.
						<br />
						<h3>Кому подойдёт?</h3>
						Кто уже на опыте и ведет несколько проектов.
						<br />
						<br />
						Три базовых чек-листа + 12 продвинутых:
						<br />• Упаковка бренда и продукта;
						<br />
						• Нейминг;
						<br />
						• Сторителлинг;
						<br />
						• Таргетинг;
						<br />
						• Комьюнити менеджмент и TOV;
						<br />
						• Поисковая оптимизация;
						<br />
						• Инструменты в Digital;
						<br /> и многое другое.
					</div>
				)}
				{selectedProduct === 'consultation' && (
					<div className='text'>
						Стоимость:
						<br />
						1 час - 1490 рублей.
						<br />
						2 часа - 2500 рублей.
						<br />
						<h3>О чем можно нас спрашивать:</h3>
						• Разбор SMM-стратегии,
						<br />
						• Оценка контент-плана, <br />
						• Маркетинг, <br />
						• Разбор ЦА, <br />
						• Таргетированная реклама, <br />
						• Сторителлинг.
						<br />+ все чек-листы бонусом.
					</div>
				)}
				<br />
				<hr />
				<br />
				<br />
				<span className='label'>Ваше имя</span>
				<input required={true} className='input' placeholder='Имя' value={name} onChange={(e) => setName(e.target.value)} type='text'></input>
				<span className='label'>Адрес электронной почты, куда мы отправим всю необходимую информацию.</span>
				<input
					required={true}
					className='input'
					placeholder='email@yandex.ru'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					type='email'
				></input>
				<button type='submit' className='main-btn'>
					Купить
				</button>
			</form>
		</div>
	);
}
